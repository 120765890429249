import { Link } from 'react-router-dom'

export default function BrendLogo() {
	return (
		<Link to={'/'} aria-label="Home Url">
			<svg
				width='147'
				height='32'
				viewBox='0 0 147 32'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9.69159 12.8833C7.74024 12.8833 6.21122 11.4091 6.21122 9.59633C6.21122 6.49583 9.84747 5.09239 11.8938 7.10406C14.2048 9.37581 12.3729 12.8833 9.69159 12.8833ZM8.82152 0.0253143H10.3828C11.2361 0.0966024 11.6251 0.464552 11.6251 2.05554C12.226 2.10553 13.0998 2.58613 13.5587 2.82893C13.7537 2.53764 14.5687 1.86762 14.9982 1.88816C15.4968 1.91202 16.0066 2.61821 16.3382 2.94981C17.9082 4.5199 17.3839 4.20369 16.3623 5.72924C16.5876 6.15514 16.9917 6.89995 17.039 7.46942C17.9153 7.54235 19.0692 7.59438 19.0692 8.53289C19.0692 10.064 19.3326 11.1367 18.4115 11.4522C18.286 11.4952 17.2465 11.6219 17.039 11.6265C16.9807 12.3279 16.4987 12.8781 16.3623 13.4634C17.0913 14.5521 17.9174 14.6741 16.6519 15.9766L16.4237 16.2318C16.0455 16.6602 15.384 17.3305 14.8155 17.3305C14.4575 17.3305 13.7139 16.3652 13.2843 16.3919C12.9859 16.4104 12.2283 16.9822 11.5285 17.0404C11.5285 18.2323 11.4693 19.1673 10.2716 19.1673C7.98634 19.1673 7.46803 19.4414 7.46803 17.0404C6.93818 16.7862 6.63611 16.7476 5.72785 16.267C5.0548 16.7177 4.35821 17.8455 3.38057 16.9709C3.05624 16.6807 2.78096 16.3625 2.46478 16.0497C1.16892 14.7678 1.88597 14.6762 2.82755 13.2701C2.51408 12.9094 2.25585 12.1741 2.1508 11.7232C1.55912 11.71 0.0239258 11.614 0.0239258 10.8531V8.52595C0.138001 7.81289 0.579614 7.59895 2.05416 7.5661C2.20437 6.92118 2.45514 6.34704 2.73086 5.82592C2.35579 5.26581 1.76411 4.67723 1.76411 4.18242C1.76411 3.71424 3.03962 2.51466 3.3897 2.23071C4.3797 1.42776 4.87893 2.3252 5.63116 2.82893C6.29245 2.38611 6.89506 2.31318 7.56471 1.95886C7.56471 0.821248 7.67221 0.0253143 8.82152 0.0253143Z'
					fill='#0C0F5D'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M21.5827 17.5238C21.5827 16.2634 22.7705 14.9136 23.9996 14.9136H24.6764C25.9689 14.9136 27.0933 16.2935 27.0933 17.6205C27.0933 21.1218 21.5827 21.3481 21.5827 17.5238ZM22.1628 11.6265C21.5496 11.7694 21.1136 12.1366 20.616 12.4C19.248 11.4839 19.1895 11.6827 18.3199 12.6175C17.1862 13.8363 17.629 14.0496 18.2296 14.6947C18.6462 15.142 18.3321 15.3281 18.1997 15.8807C18.0056 16.6904 18.1647 16.4843 17.3349 16.6597C16.192 16.9014 16.4244 17.8438 16.5666 18.9629C16.7525 20.4248 18.0499 19.5142 18.4916 20.1315L19.0691 21.1975C18.1863 22.516 18.0551 22.579 19.1905 23.5897C20.6651 24.9024 20.6275 24.1255 21.6794 23.4211C22.0088 23.4979 22.2787 23.6403 22.6959 23.7581C23.6906 24.0388 22.7357 24.8022 23.7427 25.4195C24.13 25.6569 24.5259 25.4782 24.9694 25.4546C27.5498 25.3173 25.5981 23.9575 26.979 23.4036C27.3702 23.2466 27.5131 23.0426 27.9634 22.9377C29.3828 23.8882 29.4828 23.6173 30.2621 22.7229C31.0352 21.8356 31.2108 21.6349 30.6182 20.863C30.4054 20.5857 30.3632 20.5102 30.0903 20.3275C30.2648 19.9637 30.5336 19.2615 30.5737 18.7806C31.7618 18.7542 32.1205 18.3879 32.1205 17.5238C32.1205 15.7542 31.9246 15.3969 30.2836 15.3969C30.185 14.9737 29.825 14.3692 29.6069 14.0435C30.595 12.5678 30.238 12.5003 29.391 11.7458C27.8976 10.4156 28.0004 11.2125 27.0933 11.8199C26.56 11.6957 26.1168 11.392 25.4498 11.3365C25.4498 10.8732 25.3399 10.2925 25.0849 10.0579C24.7322 9.73328 24.275 9.81714 23.7915 9.87023C23.179 9.93746 22.7816 9.87652 22.4536 10.1772C22.0792 10.5203 22.1628 10.9422 22.1628 11.6265Z'
					fill='#0C0F5D'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M12.6884 27.6749C10.5938 27.6749 9.79773 25.2776 11.065 23.9246C12.3598 22.5423 14.9119 23.3152 14.9119 25.548C14.9119 26.7198 13.8905 27.6749 12.6884 27.6749ZM10.0781 20.9075C8.96686 20.1634 8.9623 20.2638 8.05631 21.1093C7.9632 21.1962 8.04487 21.1051 7.90986 21.2535C7.8864 21.2792 7.78365 21.4056 7.77711 21.4149C7.34591 22.0299 7.84047 22.4836 8.14459 22.9377C8.03833 23.1385 7.94318 23.3253 7.87087 23.5341C7.70918 24.0012 7.86831 23.9807 7.46247 24.0925C6.27308 24.4202 6.30772 24.1853 6.30772 26.1281C6.30772 26.7311 7.29413 26.8138 7.34945 26.8265C7.9119 26.9561 7.61794 27.022 7.98341 27.6427L8.14459 27.8683C7.56869 28.7283 7.11805 28.9867 8.02101 29.9254C8.30251 30.218 8.57282 30.5272 9.0003 30.5204C9.53582 30.5118 9.91678 30.0134 10.0781 29.9951C10.4535 30.0954 10.6648 30.3422 11.1416 30.3819C11.268 31.9009 11.7097 31.9287 12.8818 31.9287C13.7037 31.9287 13.9452 31.2065 13.9452 30.4785C14.4697 30.4349 14.8574 30.0918 15.1053 30.0918C15.5832 30.0918 15.9462 31.2428 17.1133 30.0696C18.0965 29.0812 17.7331 28.9539 17.1355 28.0616L17.5036 27.3662C17.8685 26.3947 18.2506 27.2707 18.8028 26.5386C19.0165 26.2553 19.6971 24.1945 17.7156 24.1945C17.6217 23.7913 17.4876 23.3283 17.2322 23.0344C17.5339 22.5839 18.0135 22.2352 17.6426 21.5654C17.4242 21.1711 16.8615 20.5208 16.2654 20.5208C15.8877 20.5208 15.5523 20.8344 15.2987 21.0042C15.01 20.7533 14.5428 20.5545 14.1386 20.5208C14.1032 18.9357 13.2474 19.1674 12.1083 19.1674C11.907 19.1674 11.6987 19.389 11.6012 19.5303C11.3408 19.9077 11.5219 19.9167 11.368 20.2638C11.2336 20.5667 11.0685 20.5261 10.7711 20.6338C10.5056 20.7299 10.3859 20.8358 10.0781 20.9075Z'
					fill='#0C0F5D'
				/>
				<path
					d='M39.3066 25.1664V6.31953L53.2022 6.34613V10.3125H43.6191V13.8796L51.6583 13.9062L51.6849 17.7128H43.6191V21.2H53.2022V25.1664H39.3066Z'
					fill='#0C0F5D'
				/>
				<path
					d='M55.9438 25.1664V6.31953H60.1232L68.4552 18.0855V6.31953H72.7942V25.1664H68.7214L60.2563 13.5335V25.1664H55.9438Z'
					fill='#0C0F5D'
				/>
				<path
					d='M76.5742 25.1664V6.31953L80.8866 6.34613V25.1664H76.5742Z'
					fill='#0C0F5D'
				/>
				<path
					d='M92.7856 25.3793C90.9045 25.3793 89.2762 24.98 87.9009 24.1815C86.5255 23.3829 85.4696 22.2604 84.7331 20.814C83.9967 19.3677 83.6284 17.6596 83.6284 15.6897C83.6284 14.2168 83.8369 12.8946 84.254 11.7234C84.671 10.5521 85.2789 9.54496 86.0774 8.702C86.876 7.85904 87.8388 7.21131 88.9657 6.75877C90.0926 6.30623 91.3659 6.07995 92.7856 6.07995C94.2231 6.07995 95.5319 6.31954 96.7121 6.79868C97.8922 7.27785 98.8683 8.00104 99.6402 8.96819C100.412 9.93539 100.914 11.1555 101.144 12.6284H96.8318C96.7076 12.0605 96.4769 11.5947 96.1397 11.2309C95.8026 10.8671 95.3766 10.5965 94.862 10.419C94.3473 10.2415 93.7528 10.1528 93.0785 10.1528C92.1556 10.1528 91.3748 10.3125 90.7359 10.6319C90.097 10.9514 89.5824 11.3817 89.192 11.923C88.8015 12.4643 88.5176 13.0721 88.3401 13.7465C88.1626 14.4208 88.0739 15.113 88.0739 15.8228C88.0739 16.7634 88.2381 17.6551 88.5664 18.4981C88.8947 19.3411 89.4182 20.0199 90.137 20.5345C90.8557 21.0492 91.8096 21.3065 92.9986 21.3065C93.6907 21.3065 94.3296 21.2089 94.9152 21.0137C95.5009 20.8184 95.98 20.5123 96.3527 20.0953C96.7254 19.6782 96.9472 19.1592 97.0182 18.538H92.2266V14.8645H101.517V15.7429C101.517 17.766 101.198 19.4964 100.559 20.9338C99.9197 22.3713 98.957 23.4716 97.6704 24.2347C96.3838 24.9978 94.7555 25.3793 92.7856 25.3793Z'
					fill='#0C0F5D'
				/>
				<path
					d='M104.259 25.1664V6.31953H109.849L114.641 14.9976L119.432 6.31953H124.809V25.1664H120.47V12.2824L115.439 21.2H113.709L108.571 12.2824V25.1664H104.259Z'
					fill='#0C0F5D'
				/>
				<path
					d='M126.646 25.1664L134.1 6.31953H138.678L146.052 25.1664H141.394L140.355 22.2382H132.343L131.278 25.1664H126.646ZM133.7 18.3251H138.998L136.389 11.0312L133.7 18.3251Z'
					fill='#0C0F5D'
				/>
			</svg>
		</Link>
	)
}
